import React, { Fragment } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import { TableCell } from '@material-ui/core';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { format } from 'date-fns';
import { Tooltip } from '@material-ui/core';
import styles from 'views/styles';
import { ApiKeyData } from 'models/ApiKey';
import Icon from 'views/components/Icon';
import ActionBtn from 'views/components/table/ActionBtn';
import { btnMessages } from 'views/components/Button';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import BaseHoverableTableRow from 'views/components/table/HoverableTableRow';
import CopyTextButton from 'views/components/CopyText/CopyTextButton';
import { FULL_DATE_FORMAT } from 'utils/constants';
import { DeleteApiKeyDialog } from '../ApiKeysTable/DeleteApiKeyDialog';

interface IApiKeyTableRow {
  rowData: ApiKeyData;
  archiveApiKey: ((key: string) => void) | null;
  team: number;
}

const HoverableTableRow = styled(BaseHoverableTableRow)`
  cursor: pointer;
`;

const Actions = styled.div`
  white-space: nowrap;
  display: inline-flex;
`;

const formatDate = (date: Date) => {
  return format(date, FULL_DATE_FORMAT);
};

const ApiKeyTableRow = ({ rowData, team, archiveApiKey }: IApiKeyTableRow) => {
  const intl = useIntl();
  const { classes } = useStyles();

  return (
    <Fragment>
      <HoverableTableRow
        renderCells={() => (
          <Fragment>
            <TableCell width={300}>
              <div css={classes.cellContainer}>
                <span css={classes.mt}>{rowData.name || '-'}</span>
                {rowData.expiration && new Date(rowData.expiration) <= new Date() ? (
                  <Tooltip title='API Key is expired and no longer active' placement='top'>
                    <div>
                      <Icon css={classes.iconWarning} name='warning' />
                    </div>
                  </Tooltip>
                ) : null}
              </div>
            </TableCell>
            {team ? (
              <TableCell width={300}>{rowData.team?.name}</TableCell>
            ) : (
              <TableCell width={300}></TableCell>
            )}
            <TableCell>
              <Tooltip title={rowData.key} placement='top-start'>
                <span>
                  {`${rowData.key.substring(0, 25)}…`} <CopyButton text={rowData.key} />
                </span>
              </Tooltip>
            </TableCell>
            <TableCell align='right'>
              <Actions>
                <Tooltip
                  title={
                    rowData.created && rowData.expiration
                      ? `Created: ${formatDate(new Date(rowData.created))} UTC
                      Expires: ${formatDate(new Date(rowData.expiration))} UTC`
                      : rowData.created
                      ? `Created: ${formatDate(new Date(rowData.created))} UTC
                        Expires: Never Expires`
                      : ''
                  }
                  placement='top'>
                  <div>
                    <ActionBtn
                      name='info'
                      css={classes.btnInfo}
                      aria-label={intl.formatMessage(btnMessages.info)}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        return false;
                      }}
                    />
                  </div>
                </Tooltip>
                {archiveApiKey && (
                  <Tooltip title='Delete API Key' placement='top'>
                    <div>
                      <DeleteApiKeyDialog onDeleteApiKey={() => archiveApiKey(rowData.key)} />
                    </div>
                  </Tooltip>
                )}
              </Actions>
            </TableCell>
          </Fragment>
        )}
      />
    </Fragment>
  );
};

const CopyButton = styled(CopyTextButton)`
  font-size: 0.4rem;
  margin-left: 0.6rem;
  vertical-align: middle;
`;

const useStyles = makeStyles({
  base: {
    iconWarning: {
      color: styles.color.lightRed,
      width: '1.8rem !important',
      height: '1.8rem !important',
    },
    cellContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
    },
    mt: {
      marginTop: '-5px',
    },
    btnInfo: {
      marginLeft: 8,
      fontSize: '0.6rem',
      color: styles.color.lightGrey,
    },
  },
  light: {},
  dark: {},
});
export default ApiKeyTableRow;
