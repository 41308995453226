import React from 'react';
import { jsx } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { useHistory } from 'react-router-dom';
import styles from 'views/styles';
import { RootState } from 'state/root';
import { Artifact, AssertionMetadata } from 'models/Submission';
import { openModal } from 'state/modal/actions';
import { errorSelector } from 'state/requests/selectors';
import { isErrorOfStatus, isErrorOfType } from 'utils/error';
import { SubmissionActionName } from 'state/submission/types';
import { ScannerDetailsModalParams } from '../../../modals/ScannerDetailsModal';
import Button from '@material-ui/core/Button';
import { btnMessages } from 'views/components/Button';
import ErrorPageContent, {
  messages as errorPageMessages,
} from 'views/components/error/ErrorPageContent';
import DetectionsTable from './DetectionsTable';
import { useUser } from 'views/components/providers/UserProvider';

interface IDetectionsTab {
  file: Artifact | null;
  uuid: string;
  isScanning: boolean;
  onRescan: () => void;
  onRetryScan: () => void;
}

const messages = defineMessages({
  notFoundHeading: {
    id: 'scan.results.detections.notFound.heading',
    defaultMessage: 'Hash not found',
  },
  notFoundText: {
    id: 'scan.results.detections.notFound.text',
    defaultMessage: 'Artifact with hash {hash} was not found.',
  },
  notScannedHeader: {
    id: 'scan.results.detections.notScanned.heading',
    defaultMessage: 'Not Scanned',
  },
  notScannedText: {
    id: 'scan.results.detections.notScanned.text',
    defaultMessage:
      'This artifact was found, but has not been scanned. Click the Scan Now button to initiate a scan.',
  },
  rateLimitExceededHeading: {
    id: 'scan.results.detections.rateLimitExceeded.heading',
    defaultMessage: 'Rate Limit Exceeded',
  },
  rateLimitExceededText: {
    id: 'scan.results.detections.rateLimitExceeded.text',
    defaultMessage: 'You have exceeded your rate limit, please try again in 1 hour.',
  },
  invalidHashHeading: {
    id: 'scan.results.detections.invalidHash.heading',
    defaultMessage: 'Invalid Hash',
  },
  invalidHashText: {
    id: 'scan.results.detections.invalidHash.text',
    defaultMessage: 'Provided hash is invalid, plase try another one.',
  },
  rescanBtn: {
    id: 'scan.results.detections.rescanBtn.label',
    defaultMessage: 'Scan Now',
  },
  goHomeBtn: {
    id: 'scan.results.detections.goHome.label',
    defaultMessage: 'Go Back to Home Page',
  },
});

const DetectionsTab = ({ file, uuid, isScanning, onRescan, onRetryScan }: IDetectionsTab) => {
  const intl = useIntl();
  const user = useUser();
  const history = useHistory();
  const dispatch = useDispatch();
  const { classes } = useStyles();

  const { requests } = useSelector((state: RootState) => state);
  const error = errorSelector(requests, [SubmissionActionName.GET_SUBMISSION]);
  const loading = useSelector((state: RootState) => state.submission.loadingExtra);

  const _openMetadataModal = (
    authorName: string | null,
    author: string,
    metadata: AssertionMetadata
  ) => {
    dispatch(
      openModal<ScannerDetailsModalParams>('SCANNER_DETAILS', { authorName, author, metadata })
    );
  };

  const onRetrySubmit = () => {
    history.push('/');
  };

  if (error) {
    if (isErrorOfStatus(404, error)) {
      return (
        <ErrorPageContent
          className='h-mt-lg h-mb-lg'
          heading={intl.formatMessage(messages.notFoundHeading)}
          text={intl.formatMessage(messages.notFoundText, { hash: uuid })}
        >
          <Button
            data-cy='scanSearchRetry'
            style={{ fontSize: '2.4rem' }}
            color='primary'
            variant='contained'
            onClick={onRetrySubmit}
          >
            <FormattedMessage {...btnMessages.goBack} />
          </Button>
        </ErrorPageContent>
      );
    } else if (isErrorOfType('rate_limit_exceeded', error)) {
      return (
        <ErrorPageContent
          className='h-mt-lg h-mb-lg'
          heading={intl.formatMessage(messages.rateLimitExceededHeading)}
          text={intl.formatMessage(messages.rateLimitExceededText)}
        >
          <Button
            data-cy='scanSearchRetry'
            style={{ fontSize: '2.4rem' }}
            color='primary'
            variant='contained'
            onClick={onRetryScan}
          >
            <FormattedMessage {...errorPageMessages.buttonReload} />
          </Button>
        </ErrorPageContent>
      );
    } else if (isErrorOfType('invalid_hash', error)) {
      return (
        <ErrorPageContent
          className='h-mt-lg h-mb-lg'
          heading={intl.formatMessage(messages.invalidHashHeading)}
          text={intl.formatMessage(messages.invalidHashText)}
        >
          <Button
            data-cy='scanSearchRetry'
            style={{ fontSize: '2.4rem' }}
            color='primary'
            variant='contained'
            onClick={onRetrySubmit}
          >
            <FormattedMessage {...messages.goHomeBtn} />
          </Button>
        </ErrorPageContent>
      );
    } else {
      return (
        <ErrorPageContent
          className='h-mt-lg h-mb-lg'
          heading={intl.formatMessage(errorPageMessages.headingReload)}
          text={intl.formatMessage(errorPageMessages.textReload)}
        >
          <Button
            data-cy='scanSearchRetry'
            style={{ fontSize: '2.4rem' }}
            color='primary'
            variant='contained'
            onClick={onRetryScan}
          >
            <FormattedMessage {...errorPageMessages.buttonReload} />
          </Button>
        </ErrorPageContent>
      );
    }
  }

  // Identify placeholder entity
  if (file && (file.window_closed === null || file.community === 'stream')) {
    return (
      <ErrorPageContent
        className='h-mt-lg h-mb-lg'
        heading={intl.formatMessage(messages.notScannedHeader)}
        text={intl.formatMessage(messages.notScannedText)}
      >
        <Button
          style={{ fontSize: '2.4rem' }}
          color='primary'
          variant='contained'
          onClick={onRescan}
        >
          <FormattedMessage {...messages.rescanBtn} />
        </Button>
      </ErrorPageContent>
    );
  }

  return (
    <div css={classes.container}>
      {user.accountNumber !== -1 && (
        <Button
          variant='text'
          css={classes.reportFalsePositiveBtn}
          onClick={() => {
            dispatch(
              openModal('FALSE_POSITIVE_MODAL', {
                instanceId: file?.id,
                sha256: file?.sha256,
              })
            );
          }}
        >
          Report False Positive
        </Button>
      )}
      <DetectionsTable scanning={isScanning || loading} file={file} onClick={_openMetadataModal} />
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
    },
    reportFalsePositiveBtn: {
      alignSelf: 'flex-end !important',
      marginRight: '3rem !important',
      fontSize: '1.35rem !important',
      fontWeight: 600,
    },
  },
  light: {
    reportFalsePositiveBtn: {
      color: `${styles.color.purple} !important`,
    },
  },
  dark: {
    reportFalsePositiveBtn: {
      color: `${styles.color.lightBlue} !important`,
    },
  },
});

export default DetectionsTab;
