import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { translateError, ErrorKeys } from 'utils/error';
import { loadingSelector, errorSelector } from 'state/requests/selectors';
import { ModalState } from 'state/modal';
import { closeModal, openModal } from 'state/modal/actions';
import { RootState } from 'state/root';
import ConfirmModal from 'views/components/Modal/ConfirmModal';
import { archiveApiKey, getApiKeys } from 'state/apiKeys/actions';
import { ApiKeysActionName } from 'state/apiKeys/types';
import config from 'config';

interface ConfirmApiKeyDeletionParams {
  key: string;
}

const messages = defineMessages({
  text: {
    id: 'account.deleteApiKey.account.confirm.text',
    defaultMessage: 'Confirm deletion of API Key {key}?',
  },
  mfaSuccess: {
    id: 'account.deleteApiKey.account.confirm.success',
    defaultMessage: 'API Key deleted',
  },
  mfaFailure: {
    id: 'account.deleteApiKey.account.confirm.failure',
    defaultMessage: 'Error deleting API Key. Please try again.',
  },
});

const errorKeys: ErrorKeys = {};

const ConfirmApiKeyDeletion = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { requests } = useSelector((state: RootState) => state);
  const isLoading = loadingSelector(requests, [ApiKeysActionName.ARCHIVE_API_KEY]);
  const error = errorSelector(requests, [ApiKeysActionName.ARCHIVE_API_KEY]);

  const { key } = useSelector(
    ({ modal }: { modal: ModalState<ConfirmApiKeyDeletionParams> }) => modal.params
  );

  return (
    <ConfirmModal
      isLoading={isLoading}
      errorMessage={translateError(intl, errorKeys, error)}
      text={intl.formatMessage(messages.text, { key })}
      submitBtnText={intl.formatMessage({ id: 'button.confirm' })}
      onSubmit={async () => {
        await dispatch(archiveApiKey(key));
        dispatch(getApiKeys(undefined, true));
        dispatch(closeModal());
      }}
      onCancel={() => dispatch(closeModal())}
      timeout={parseInt(config.tokenExpirationSec, 10)}
      onTimeout={() => {
        dispatch(closeModal());
        dispatch(openModal('SESSION_EXPIRED'));
      }}
    />
  );
};

export default ConfirmApiKeyDeletion;
