import React, { Fragment } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import { Filter } from 'views/components/utils';
import Icon from 'views/components/Icon';

export interface IStatusIcon {
  className?: string;
  sidebar?: boolean;
  status: 'info' | 'ok' | 'error' | 'reload' | 'fail' | 'malicious';
  value?: number | string | null;
}

const StatusIcon = (props: IStatusIcon) => {
  const { className, status, sidebar = false } = props;
  const { classes } = useStyles(status);
  return (
    <div className={className} css={[classes.root, sidebar ? classes.mt5 : null]}>
      <Filter
        currentState={status}
        states={{
          info: <IconInfoState {...props} />,
          ok: <IconOkState {...props} />,
          error: <IconErrorState {...props} />,
          reload: <IconReloadState {...props} />,
          fail: <IconFailState {...props} />,
          malicious: <IconMaliciousState {...props} />,
        }}
      />
      {status !== 'malicious' ? (
        <svg
          css={classes.icon}
          viewBox='0 0 162 178'
          version='1.1'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-155.000000, -150.000000)'>
              <g transform='translate(60.000000, 119.000000)'>
                <g transform='translate(43.000000, 31.000000)'>
                  <g transform='translate(52.000000, 0.000000)'>
                    <path
                      css={classes.outerLine}
                      d='M95.3525404,5.1398734 C86.2252086,-0.129794051 74.9798721,-0.129794051 65.8525404,5.1398734 L15.3525404,34.296062 C6.22520861,39.5657294 0.602540378,49.3044765 0.602540378,59.8438114 L0.602540378,118.156189 C0.602540378,128.695523 6.22520861,138.434271 15.3525404,143.703938 L65.8525404,172.860127 C74.9798721,178.129794 86.2252086,178.129794 95.3525404,172.860127 L145.85254,143.703938 C154.979872,138.434271 160.60254,128.695523 160.60254,118.156189 L160.60254,59.8438114 C160.60254,49.3044765 154.979872,39.5657294 145.85254,34.296062 L95.3525404,5.1398734 Z'
                    />
                    <path
                      css={classes.innerLine}
                      strokeWidth='2'
                      d='M93.6025404,19.9511078 C85.5581124,15.3066552 75.6469684,15.3066552 67.6025404,19.9511078 L27.3044457,43.2172237 C19.2600177,47.8616763 14.3044457,56.4449788 14.3044457,65.7338842 L14.3044457,112.266116 C14.3044457,121.555021 19.2600177,130.138324 27.3044457,134.782776 L67.6025404,158.048892 C75.6469684,162.693345 85.5581124,162.693345 93.6025404,158.048892 L133.900635,134.782776 C141.945063,130.138324 146.900635,121.555021 146.900635,112.266116 L146.900635,65.7338842 C146.900635,56.4449788 141.945063,47.8616763 133.900635,43.2172237 L93.6025404,19.9511078 Z'
                    />
                    <path
                      css={classes.polygon}
                      strokeWidth='3'
                      d='M93.3525404,34.5847371 C85.4628129,30.0296009 75.7422678,30.0296009 67.8525404,34.5847371 L39.8525404,50.7505447 C31.9628129,55.3056809 27.1025404,63.7239199 27.1025404,72.8341925 L27.1025404,105.165808 C27.1025404,114.27608 31.9628129,122.694319 39.8525404,127.249455 L67.8525404,143.415263 C75.7422678,147.970399 85.4628129,147.970399 93.3525404,143.415263 L121.35254,127.249455 C129.242268,122.694319 134.10254,114.27608 134.10254,105.165808 L134.10254,72.8341925 C134.10254,63.7239199 129.242268,55.3056809 121.35254,50.7505447 L93.3525404,34.5847371 Z'
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      ) : null}
    </div>
  );
};

const IconInfoState = ({ status, value }: IStatusIcon) => {
  const { classes } = useStyles(status);
  return (
    <Fragment>
      {value !== null ? <span css={classes.statusText}>{String(value)}</span> : null}
    </Fragment>
  );
};

const IconOkState = ({ status, value }: IStatusIcon) => {
  const { classes } = useStyles(status);
  return (
    <Fragment>
      {value ? (
        <span css={classes.statusText}>{String(value)}</span>
      ) : (
        <span css={[classes.statusText, classes.check]}>
          <Icon name='check' />
        </span>
      )}
    </Fragment>
  );
};

const IconErrorState = ({ status, value }: IStatusIcon) => {
  const { classes } = useStyles(status);
  return (
    <Fragment>{value ? <span css={classes.statusText}>{String(value)}</span> : null}</Fragment>
  );
};

const IconReloadState = ({ status }: IStatusIcon) => {
  const { classes } = useStyles(status);
  return (
    <span css={[classes.statusText, classes.reload]}>
      <Icon name='reload' />
    </span>
  );
};

const IconFailState = ({ status }: IStatusIcon) => {
  const { classes } = useStyles(status);
  return (
    <span css={[classes.statusText, classes.reload]}>
      <Icon name='question' />
    </span>
  );
};

const IconMaliciousState = ({ status }: IStatusIcon) => {
  const { classes } = useStyles(status);
  return (
    <span css={[classes.malicious]}>
      <Icon name='malicious' />
    </span>
  );
};

const useStyles = makeStyles((status: IStatusIcon['status']) => {
  const statusColor = (infoColor: string) => {
    if (status === 'info') {
      return infoColor;
    } else if (status === 'ok') {
      return styles.color.medGreen;
    } else if (status === 'fail') {
      return styles.color.xLightGrey;
    }
    return styles.color.red;
  };

  const statusLight = statusColor(styles.color.purple);
  const statusDark = statusColor(styles.color.lightBlue);

  return {
    base: {
      root: {
        display: 'inline-block',
        position: 'relative',
        fontSize: '1rem',
        maxWidth: '16.2em',
      },
      mt5: {
        marginTop: '5rem',
      },
      icon: {
        width: '100%',
      },
      outerLine: {
        opacity: 0.25,
      },
      innerLine: {
        opacity: 0.5,
      },
      malicious: {
        display: 'block',
        fontSize: '3.9em',
        marginBottom: '3rem',
        marginTop: '2rem',
        color: styles.color.red,
      },
      statusText: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: styles.color.white,
        fontWeight: styles.font.weight.black,
        fontSize: '4.6em',
        display: 'block',
        lineHeight: 1,
      },
      check: {
        fontSize: '1.2em',
        top: '50%',
      },
      reload: {
        fontSize: '1.9em',
        top: '50%',
      },
    },
    light: {
      outerLine: {
        stroke: statusLight,
      },
      innerLine: {
        stroke: statusLight,
      },
      polygon: {
        stroke: statusLight,
        fill: statusLight,
      },
    },
    dark: {
      outerLine: {
        stroke: statusDark,
      },
      innerLine: {
        stroke: statusDark,
      },
      polygon: {
        stroke: statusDark,
        fill: 'none',
      },
    },
  };
});

export default StatusIcon;
