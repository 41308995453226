import React, { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useSticky from 'hooks/useSticky';
import { RootState } from 'state/root';
import { NormalizedArtifactMetadata } from 'models/Submission';
import { HuntingActionName } from 'state/hunting/types';
import { loadingSelector } from 'state/requests/selectors';

interface Props {
  renderSidebar: ReactNode;
  renderPanel: ReactNode;
  sidebarSticky?: boolean;
}

interface IMetadataSearchTabState {
  metadataSearchResults: NormalizedArtifactMetadata[];
}

const PageSidebarLayout = ({ renderSidebar, renderPanel, sidebarSticky = false }: Props) => {
  const location = useLocation();
  const [ref, nextElementRef] = useSticky<HTMLDivElement>({
    topOffset: 10,
    scrollMargin: 100,
    stickyActive: sidebarSticky,
  });
  const { hashSearchResults } = useSelector((state: RootState) => state.submission);

  const { metadataSearchResults } = useSelector<RootState, IMetadataSearchTabState>(
    ({ hunting: { metadataSearchResults }, requests }) => ({
      isLoading: loadingSelector(requests, [HuntingActionName.GET_METADATA_SEARCH_RESULTS]),
      metadataSearchResults,
    })
  );

  let sidebarActive;
  if (location.pathname === '/search/hash') {
    sidebarActive = Object.keys(hashSearchResults).length === 0;
  } else {
    sidebarActive = metadataSearchResults.length === 0;
  }

  return (
    <div className='container h-mt-sm h-mb-lg'>
      <div className='row'>
        {sidebarActive && (
          <div ref={ref} className='col-5 col-sm-4 col-md-4 col-lg-4'>
            {typeof renderSidebar === 'function' ? renderSidebar() : renderSidebar}
          </div>
        )}
        <div
          ref={nextElementRef}
          className={`col-${sidebarActive ? '7' : '12'} ${
            sidebarActive ? 'col-sm-7 col-md-8' : ''
          } ${sidebarActive ? 'col-lg-8' : 'col-lg-12'}`}
        >
          {typeof renderPanel === 'function' ? renderPanel() : renderPanel}
        </div>
      </div>
    </div>
  );
};
export default PageSidebarLayout;
